@import "../../../../style/vars.scss";

.ExchangeChart_trading-active {
	position: relative;
	padding: 0;
}
.ExchangeChart_position-active {
	position: relative;
	padding: 0;
}

.ExchangeChart_trading-active, .ExchangeChart_position-active {
	.ExchangeChart__simple {
		visibility: hidden;
		z-index: -1;
	}
} 

.ExchangeChart {
	&__simple {
		height: 100%;
	}
	&__trading {
		display: none;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 1;

		& > div:first-child {
			height: calc(100% - 68px);

			@media (max-width: 550px) {
				height: calc(100% - 82px);
			}
		}
	}
}

.Tab.Exchange-chart-tabs {
	position: absolute;
	bottom: 15px;
	right: 20px;
	z-index: 10;
	column-gap: 0;
	background-color: $blue-1;
	border-radius: 5px;
	border: $border;

	.Tab-option {
		font-size: 14px;
		line-height: calc(18.2/14);
		border-color: transparent;
		padding: 8.5px 19.5px;
	}

	@media (max-width: 480px) {
		.Tab-option {
			font-size: 13px;
			line-height: 130%;
			white-space: nowrap;
			padding: 8px 10px;
		}
	}
	@media (max-width: 380px) {
		right: 5px;
		.Tab-option {
			padding: 8px 5px;
		}
	}
}