@import '../../../style/vars.scss';

.InputSimple {
	display: flex;
	justify-content: space-between;
	align-items: center;
	column-gap: 5px;
	font-size: 15px;
	line-height: calc(19.5/15);
	background-color: $blue-15;
	border: $border;
	border-radius: 5px;
	cursor: text;
	transition: border-color .2s ease;
	padding: 7.5px 13px;

	input {
		width: 100%;
		font-size: 15px;
		line-height: 130%;
	}

	&__unit {
		color: $grey;
		white-space: nowrap;
	}

	&.hlight {
		border-color: $white;
	}

	&.disabled {
		&, input {
			cursor: not-allowed;
		}
	}

	&.error {
		border-color: $red;
	}
}