@import '../../../style/vars.scss';

.InputWithSelector {
	display: flex;
	column-gap: 10px;
	row-gap: 5px;
	width: 100%;
	align-self: stretch;

	.InputSimple {
		flex-grow: 1;
	}
}