@import "../../../../style/vars.scss";


.Options-Interface {
	.Exchange-leverage {
		&-box {
			background-color: $blue-15;
			border: $border;
			border-radius: 5px;
			padding: 10px 15px 15px;

			.Exchange-info-row {
				&:last-child {
					margin-bottom: 0;
				}
			
				font-size: 15px;
				line-height: calc(19.5/15);
			}
		}

		&-slider {
			margin-bottom: 10px;
		}

		&-slider-settings {
			font-size: 17px;
			line-height: calc(22.1/17);
		}
		&-name {
			color: $grey;
		}
	}

	.Exchange-swap-box.scrolling {
		position: fixed;
		width: 430px;

		@media (max-width: 1380px) {
			width: 350px;
		}

		@media (max-width: 1100px) {
			position: static;
			width: 100%;
		}
	}
}
