.szh-accordion {
	&__item {
		padding: 10px 0;
		&-btn {
			width: 100%;
			display: flex;
			justify-content: space-between;
			column-gap: 20px;
			align-items: center;
			font-size: 17px;
			line-height: calc(22.1/17);
			
			@media (max-width: 576px) {
				font-size: 15px;
				line-height: calc(19.5/15);
			}
			@media (max-width: 480px) {
				font-size: 14px;
				line-height: calc(18.2/14);
			}

			&::after {
				content: '';
				display: block;
				flex-shrink: 0;
				width: 25px;
				height: 25px;
				background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2225%22%20height%3D%2225%22%20viewBox%3D%220%200%2025%2025%22%20fill%3D%22none%22%3E%0A%3Cg%20clip-path%3D%22url%28%23clip0_4753_5746%29%22%3E%0A%3Ccircle%20cx%3D%2212.5%22%20cy%3D%2212.5%22%20r%3D%2212.5%22%20fill%3D%22%2320224E%22%2F%3E%0A%3Ccircle%20cx%3D%2212.5%22%20cy%3D%2212.5%22%20r%3D%2212%22%20stroke%3D%22url%28%23paint0_linear_4753_5746%29%22%20stroke-opacity%3D%220.7%22%2F%3E%0A%3Cpath%20d%3D%22M9%2012L12.5%2014L16%2012%22%20stroke%3D%22%236988FF%22%20stroke-width%3D%221.5%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fg%3E%0A%3Cdefs%3E%0A%3ClinearGradient%20id%3D%22paint0_linear_4753_5746%22%20x1%3D%22-5.79608e-08%22%20y1%3D%22-0.29703%22%20x2%3D%2220.7361%22%20y2%3D%2228.1122%22%20gradientUnits%3D%22userSpaceOnUse%22%3E%0A%3Cstop%20stop-color%3D%22%23384263%22%20stop-opacity%3D%220.25%22%2F%3E%0A%3Cstop%20offset%3D%221%22%20stop-color%3D%22%23384263%22%20stop-opacity%3D%220.15%22%2F%3E%0A%3C%2FlinearGradient%3E%0A%3CclipPath%20id%3D%22clip0_4753_5746%22%3E%0A%3Crect%20width%3D%2225%22%20height%3D%2225%22%20fill%3D%22white%22%2F%3E%0A%3C%2FclipPath%3E%0A%3C%2Fdefs%3E%0A%3C%2Fsvg%3E');
				background-repeat: no-repeat;
				transition: transform .2s ease;
			}
		}

		&--expanded &-btn {
			&::after {
				transform: rotate(180deg);
			}
		}

		// Animation
		&-content {
			transition: height .25s ease;
		}
	}
}

.Accordion_strategy {
	.szh-accordion {
		&__item {
			display: flex;
			flex-direction: column-reverse;
			padding-top: 0;
		}
	}
}