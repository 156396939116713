.BuilderChart {
	margin-top: 30px;

	.ExchangeChart.tv {
		margin-bottom: 30px;
		
		.ExchangeChart {
			&-top {
				display: none;
			}
			&-bottom {
				top: 0;
			}
		}
	}
}