.info-board {
	border: 1px solid rgba(152, 162, 198, 0.075);
	padding: 22px 15px;
	margin-bottom: 30px;

	&__items {
		display: flex;
		justify-content: center;
	}
	
	&__item {
		display: flex;
		flex-direction: column;
		flex: 1;
		max-width: 220px;
		text-align: center;
		border-right: 1px solid $blue-1;
		padding: 15px 30px;

		&:last-child {
			border-right: none;
		}
		
		&-title {
			color: $grey;
			margin-bottom: 5px;
		}
		&-val, &-val .currency {
			justify-content: center;
			font-size: 20px;
			line-height: calc(26/20);
			color: $white;
			margin-top: auto;
		}
	}

	@media (max-width: 992px) {
		&__items {
			flex-wrap: wrap;
		}

		&__item {
			flex: auto;
			width: 33.3%;
			max-width: none;

			&:nth-child(3n) {
				border-right: none;
			}
			&:nth-child(n + 4) {
				border-top: 1px solid $blue-1;
			}
		}
	}

	@media (max-width: 576px) {
		padding: 20px;
		
		&__item {
			width: 100%;
			padding: 15px 10px;
			border: none;
			border-bottom: 1px solid $blue-1;

			&:nth-child(1) {
				padding-top: 0;
			}
			&:nth-last-child(1) {
				border-bottom: none;
				padding-bottom: 0;
			}

			&-val, &-val .currency {
				font-size: 15px;
				line-height: calc(19.5/15);
			}
		}
	}

	@media (max-width: 480px) {
		border-radius: 0;
		border: none;
	}
}