@import '../../../../style/vars.scss';

.BuyBuildModal {
	.modal__content {
		min-width: 665px;
		padding: 0;
	}
	div.divider {
		margin: 15px 0;
	}
	&__title {
		margin-bottom: 0;
	}
	&__header {
		display: flex;
		justify-content: space-between;
		column-gap: 30px;
		align-items: center;
		margin-bottom: 15px;
	}
	&__strategy {
		font-size: 15px;
		line-height: 1;
		background-color: $blue-15;
		border: $border;
		border-radius: 5px;
		cursor: default;
		padding: 8px 11px;
	}
	&__body {
		padding: 20px 30px 0;
	}
	&__footer {
		background-color: $blue-15;
		border-top: $border;
		padding: 15px 30px 25px;
	}
	&__money {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		column-gap: 30px;
		font-size: 15px;
		line-height: calc(19.5/15);
		color: $lgrey;
		margin-bottom: 5px;

		&-item {
			display: flex;
			column-gap: 15px;
			align-items: flex-start;
			&-val {
				display: flex;
				flex-direction: column;
				row-gap: 8px;
				margin-top: 3px;
			}
		}
		&-group {
			display: flex;
			flex-direction: column;
			row-gap: 5px;
		}
		&-group &-item {
			justify-content: space-between;
		}
	}

	&__table {
		width: 100%;
		font-size: 15px;
		line-height: calc(19.5/15);
		border-collapse: collapse;
		border-spacing: 0;
		border: none;

		th {
			font-weight: 500;
			color: $grey;
			text-align: left;
			padding: 0px 10px 7px 0;
		}
		td {
			color: $lgrey;
			padding: 12px 0;
		}
		tr {
			border-color: transparent;
			border-bottom: $border;
			&:first-child {
				border-bottom-color: $blue-1;
			}
			&:last-child {
				border-bottom-color: transparent;
			}
		}
		

		&-strike {
			display: flex;
			align-items: center;
			column-gap: 7px;

			&-label {
				font-size: 12px;
				line-height: calc(15.6/12);
				border: $border;
				border-radius: 5px;
				background-color: $blue-05;
				padding: 2px 4.5px;
			}
		}
	}

	&__mobile {
		display: none;

		.Currency {
			font-size: 17px;
			line-height: 130%;
		}
	}
	@media (max-width: 665px) {
		&__table {
			display: none;
		}
		.modal__content {
			min-width: auto;
		}

		&__strategy {
			display: none;
		}

		&__mobile {
			&, .divider {
				display: block;
			}
		}

		&__money {
			flex-direction: column-reverse;
			row-gap: 5px;
			&-group, &-item {
				width: 100%;
				justify-content: space-between;
			}
			&-item-val {
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: flex-end;
				column-gap: 10px;
			}
		}

		.modal__steps {
			display: flex;
			justify-content: space-between;
		}
	}
	@media (max-width: 576px) {
		&__mobile {
			.Currency {
				font-size: 15px;
				line-height: 130%;
			}
		}
	}
	@media (max-width: 480px) {
		&__money {
			align-items: flex-start;
			row-gap: 5px;

			&-item {
				width: 100%;
				justify-content: space-between;
			}
		}

		&__mobile .Exchange-info-row {
			.Exchange-info-label + .align-right, .Exchange-info-label {
				font-size: 14px;
				line-height: calc(18.2/14);
			}
		}
	}
}