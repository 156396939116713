@import '../style/vars.scss';

.Tabs {
	display: flex;
	align-items: center;
	column-gap: 40px;
	border-bottom: $border;
	margin-bottom: 30px;

	&__item {
		transform: translateY(1px);
		font-size: 17px;
		line-height: calc(22.1/17);
		color: $lgrey;
		white-space: nowrap;
		transition: color .2s ease;
		border-bottom: 1px solid transparent;
		padding: 10px 0;
		
		&:hover, &:focus-visible {
			color: $white;
		}
		&.active {
			color: $white;
			border-bottom-color: $lblue;
		}
	}

	@media (max-width: 576px) {
		justify-content: space-between;
		column-gap: 30px;
		&__item {
			font-size: 14px;
			line-height: calc(18.2/14);
		}
		
		.btn_tab {
			flex: 1;
		}
	}
}