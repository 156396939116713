.pages {
	display: flex;
	align-items: center;
	gap: 10px;

	&-container {
		display: flex;
		justify-content: center;
		margin-top: 15px;

		&_in-table {
			position: relative;
			top: -3px;
			background-color: $blue-2;
			border: $border;
			border-radius: 0 0 5px 5px;
			padding: 28px 10px 25px;
			margin-top: 0;

			@media (max-width: 850px) {
				top: 0;
			}
			@media (max-width: 480px) {	
				padding: 15px 10px 20px;
			}
		}
	}

	&-btn {
		position: relative;
		height: 100%;
		svg path {
			stroke: $white;
			transition: stroke .2s ease;
		}
		&[disabled] {
			svg path {
				stroke: $grey;
			}
		}
		&:not([disabled]):hover, &:focus-visible {
			svg path {
				stroke: $lblue;
			}	
		}
		&__icon-container {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}
	.page, &-btn {
		flex: 1;
		width: 30px;
		height: 30px;
		line-height: 0;
		text-align: center;
		color: $lgrey;
		background-color: $blue-1;
		border-radius: 5px;
		padding: 5.5px 0;
		&.current {
			color: $white;
			border-color: $lblue;
		}
	}

	.page {
		transition: none;
	}
	@media (max-width: 480px) {
		column-gap: 6px;
	}
}
