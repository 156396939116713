.MarginAccount {
	&__header {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		column-gap: 20px;
		margin-bottom: 30px;
	}

	&__title {
		margin-bottom: 0;
	}

	&__account-link {
		display: flex;
		column-gap: 10px;
		align-items: center;
		color: $lgrey;
		font-size: 15px;
		line-height: calc(19.5/15);
		transform: translateY(-5px);

		transition: color .2s ease;
		&:hover, &:focus-visible {
			color: $white;
		}
	}

	&__sections {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: auto auto;
		gap: 30px;
	}
	&__section_complex {
		grid-column: 2/4;
	}
	
	&__section {
		padding: 20px 30px 30px;
		margin: 0;

		&-icon {
			display: inline-block;
			line-height: 0;
			background-color: $blue-05;
			border-radius: 5px;
			padding: 10px;
			margin-bottom: 20px;

			img {
				width: 30px;
				height: 30px;
			}
		}
		&-btn {
			font-size: 17px;
			line-height: calc(22.1/17);
		}
		
		&-title {
			width: fit-content;
			font-size: 15px;
			line-height: calc(19.5/15);
			color: $lgrey;
			margin-bottom: 2px;
		}
		&-value, &-value .currency {
			font-size: 20px;
			line-height: calc(26/20);
			color: $white;

			&_minor {
				font-size: 17px;
				line-height: calc(22.1/17);
				color: $lgrey;
			}
		}
		&-value {
			display: grid;
			align-items: center;
			grid-template-columns: auto min-content;
			grid-template-rows: auto auto;
			row-gap: 8px;
			margin-bottom: 15px;

			&_major {
				grid-column: 1/3;
			}
		}
		&-price {
			color: $white;
			
			span {
				margin-right: 10px;
			}
		}
		
		&:first-child {
			background-color: $blue-15;
		}

		&.coming-soon {
			position: relative;
			min-height: 185px;
			background: url('../../img/card-btc.png') no-repeat;
			background-size: cover;
			overflow: hidden;

			&::after {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				z-index: 1;
				background: linear-gradient(rgba(152, 162, 198, 0.4) 0%,
				rgba(152, 162, 198, 0.04) 100%,
				rgba(152, 162, 198, 0.3) 100%,
				rgba(152, 162, 198, 0.1) 100%)
			}

			.coming-soon__text {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				z-index: 2;
				display: flex;
				justify-content: center;
				align-items: center;
				column-gap: 10px;
				font-size: 20px;
				line-height: calc(26/20);
				font-family: $title;

				&::after {
					content: 'Coming soon';
				}

				img {
					width: 25px;
					height: 25px;
				}
			}
		}

		&_complex {
			display: flex;
			column-gap: 17px;
			padding: 20px;
		}
		&-child {
			flex-basis: 50%;
			background-color: $blue-15;
			padding: 15px 20px 20px;

			.MarginAccount__section-value {
				grid-template-rows: auto;
				
				&_major {
					grid-column: auto;
				}

				&, .currency {
					font-size: 25px;
					line-height: calc(32.5/25);
				}
			}
		}
	}

	.Margin-level {
		height: 100%;
		margin: 0;

		.divider {
			margin: 36px 0 10px;
		}

		.text-table:last-child {
			.text-table__right {
				font-size: 17px;
				line-height: calc(22.1/17);
			}
		}
	}

	.has-hint-tooltip {
		.Tooltip-popup {
			left: calc(100% - 36px);
		}

		@media (max-width: 480px) {
			&#margin-balance-tooltip {
				.Tooltip-popup {
					left: 0;
					&::before {
						left: 96px;
					}
				}
			}
			&#account-balance-tooltip {
				.Tooltip-popup {
					left: 0;
					&::before {
						left: 104px;
					}
				}
			}
		}
		
	}

	@media (max-width: 1200px) {
		&__sections {
			gap: 20px;
		}
	}

	@media (max-width: 992px) {
		&__sections {
			grid-template-columns: 1fr 1fr;
			grid-template-rows: repeat(3, auto);
		}

		&__section_complex {
			grid-column: 1/3;
		}
	}

	@media (max-width: 768px) {
		&__sections {
			grid-template-columns: 1fr;
			grid-template-rows: repeat(6, auto);
		}

		&__section_complex {
			flex-direction: column;
			grid-column: auto;
			row-gap: 15px;
		}
	}

	@media (max-width: 480px) {
		&__header {
			flex-direction: column;
			align-items: flex-start;
			row-gap: 30px;
			margin-bottom: 15px;
		}
		&__account-link {
			transform: none;
		}

		&__section {
			padding: 15px 20px 20px;

			&-title {
				font-size: 17px;
				line-height: calc(22.1/17);
			}

			&-value {
				grid-template-columns: 1fr;

				&_major {
					grid-column: auto;
				}
				&_minor {
					display: flex;
					justify-content: space-between;
				}
			}

			&-icon {
				margin-bottom: 15px;
			}
			&-child {
				padding: 15px;
			}
			&-child &-value {
				&, .currency {
					font-size: 20px;
					line-height: calc(26/20);
				}
				.currency img {
					width: 20px;
					height: 20px;
				}
			}
			&-child &-title {
				font-size: 14px;
				line-height: calc(18.2/14);
			}
		}
	}
}