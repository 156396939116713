@import '../../../../style/vars.scss';

.Custom-date {
	padding: 0 15px 15px;
	
	&__title {
		font-size: 15px;
		line-height: 130%;
		font-weight: 500;
		color: $lgrey;
		margin-bottom: 10px;
	}
	&__inputs {
		display: grid;
		grid-template-columns: auto repeat(4, 1fr);
		gap: 10px;

		& > div {
			& > * {
				width: 100%;
				height: 100%;
			}
			input {
				font-size: 15px;
				line-height: 130%;
			}
			.InputSimple {
				position: relative;
				img {
					position: absolute;
					right: 10px;
					top: calc(50% - 9px);
					z-index: 1;
					width: 15px;
					height: 15px;
				}
			}
			&:nth-child(1) {
				grid-column: 1/2;
				width: 65px;
			}
			&:nth-child(2) {
				grid-column: 2/6;
			}
			&:nth-child(3) {
				grid-column: 1/4;
			}
			&:nth-child(4) {
				grid-column: 4/6;
			}
			.btn:not(:disabled) {
				background-color: lighten($blue-1, 5);
				&:hover, &:focus-visible {
					background-color: $lblue;
				}
			}
		}
	}
	@media (max-width: 480px) {
		&__inputs {
			& > div {
				&:nth-child(3), &:nth-child(4) {
					grid-column: 1/6;
				}
			}
		}
	}
}