@keyframes bounce {
	from {
			transform: translate3d(0, 0, 0);
	}
	to {
			transform: translate3d(0, -5px, 0);
	}
}

@keyframes pulse {
	0% {
		transform: scaleX(1) scaleY(1);
	}
	13% {
		transform: scaleX(1.1) scaleY(1.1);
	}
	25% {
		transform: scaleX(1.2) scaleY(1.2);
	}
	38% {
		transform: scaleX(1.1) scaleY(1.1);
	}
	50% {
		transform: scaleX(1) scaleY(1);
	}
	63% {
		transform: scaleX(0.92) scaleY(0.92);
	}
	75% {
		transform: scaleX(0.85) scaleY(0.85);
	}
	88% {
		transform: scaleX(0.92) scaleY(0.92);
	}
	100% {
		transform: scaleX(1) scaleY(1);
	}
}