.banner {
	position: relative;
	background-color: $blue-1;
	border-radius: 5px;
	border: 1px solid rgba(56, 66, 99, 0.2);
	border-left: none;
	overflow: hidden;
	padding: 20px 30px;
	margin-bottom: 30px;
	
	&::before {
		content: '';
		position: absolute;
		display: block;
		top: 0;
		bottom: 0;
		left: 0;
		width: 4px;
		background-color: $lblue;
	}

	&__title {
		margin-bottom: 5px;
	}

	@media (max-width: 480px) {
		padding: 15px 20px;
		margin-bottom: 20px;
	}
}