.supply-market {
	&__functions {
		display: flex;
		flex-wrap: wrap;
		gap: 30px;
	}
	&__function {
		width: calc(50% - 15px);
	}

	@media (max-width: 992px) {
		&__function {
			width: 100%;
		}
	}
}

.market-card {
	padding-bottom: 45px;

	&__form {
		margin-bottom: 15px;
		
		.input-container {
			width: 100%;
		}
	}

	&__info {
		display: flex;
		flex-direction: column;
		gap: 10px;
		width: fit-content;
		
		.info-field {
			&__val {
				margin-left: auto;
				
				&.no-data {
					color: $grey;

					&::before {
						content: '—';
					}
				}
			}
		}
	}

	@media (max-width: 576px) {
		padding-bottom: 28px;
	}
	@media (max-width: 480px) {
		&__form {
			.form-field {
				flex-wrap: wrap;
			}
			.input-container, button {
				width: 100%;
			}
		}

		&__info {
			flex-direction: row;
			flex-wrap: wrap;
			gap: 20px;

			.info-field {
				flex-direction: column;
				align-items: flex-start;
				gap: 5px;
				width: 105px;

				&__val {
					margin-left: 0;
					margin-top: auto;
				}
			}
		}
	}
}