@import '../style/vars.scss';

.Currency {
  display: flex;
  align-items: center;
  column-gap: 5px;
  font-size: 15px;
  line-height: calc(19/15);
  color: $lgrey;

  &.hlight {
    color: $white;
  }

  span {
    line-height: 0;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    line-height: calc(18.2/14);

    img {
      width: 15px;
      height: 15px;
    }
  }
}