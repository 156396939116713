/* Electrolize */
@font-face {
	font-family: 'Electrolize';
	src: url('../fonts/Electrolize/Electrolize-Regular.eot') format('eot'),
		url('../fonts/Electrolize/Electrolize-Regular.ttf') format('ttf'),
		url('../fonts/Electrolize/Electrolize-Regular.woff') format('woff'),
		url('../fonts/Electrolize/Electrolize-Regular.woff2') format('woff2');
}

/* Gantari */
@font-face {
	font-family: 'Gantari';
	src: url('../fonts/Gantari/Gantari-Regular.eot') format('eot'),
		url('../fonts/Gantari/Gantari-Regular.ttf') format('ttf'),
		url('../fonts/Gantari/Gantari-Regular.woff') format('woff'),
		url('../fonts/Gantari/Gantari-Regular.woff2') format('woff2');
}

@font-face {
	font-family: 'Gantari';
	src: url('../fonts/Gantari/Gantari-Medium.eot') format('eot'),
		url('../fonts/Gantari/Gantari-Medium.ttf') format('ttf'),
		url('../fonts/Gantari/Gantari-Medium.woff') format('woff'),
		url('../fonts/Gantari/Gantari-Medium.woff2') format('woff2');
	font-weight: 500;
}