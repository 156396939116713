@import '../style/vars.scss';

.Strike {
	display: flex;
	align-items: center;
	column-gap: 7px;
	font-size: 15px;
	line-height: calc(19.5/15);

	&__sentiment {
		width: 55px;
		font-size: 12px;
		line-height: calc(15.6/12);
		text-align: center;
		background-color: $blue-05;
		border-radius: 5px;
		border: $border;
		padding: 2px;
	}
}