.Connect-wallet-modal {
  display: none;
}

.GMX-Interface {
  .Modal {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;

    &_nifty {
      .Modal__text {
        color: #747FA6;
      }

      .Modal__link {
        color: #6988ff;
      }

      .Modal-body {
        padding: 0 30px 30px;
      }

      &:not(.active) {
        z-index: -20;
      }
    }

    // Nifty Modal 3D Sign
    transition: opacity .3s ease-out;
    &-content-wrapper {
      position: relative;
      z-index: 20;
      -webkit-perspective: 1300px;
      perspective: 1300px;

      .Modal-content {
        width: 43.3rem;

        @media (max-width: 480px) {
          width: 100vw;
        }
      }
    }
  }
  
  .Modal .Tab.block {
    margin-bottom: 2rem;
  }
  
  .Modal-scrollable .Modal-content .Modal-body {
    overscroll-behavior: smooth;
    max-height: calc(80vh - 5.5rem);
    overflow-y: auto;
    padding-right: 5px;
  }
  
  .Modal-backdrop {
    position: fixed;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(3, 10, 33, 0.85);
  }
  
  .Modal-content {
    width: 43.3rem;
    z-index: 20;
    position: relative;
    max-width: 100%;
    max-height: 100vh;
    overflow: auto;
    background: #0D0F29;
    border-radius: 4px;
    overflow: auto;
    border: 1px solid rgba(56, 66, 99, 0.2);

    // Nifty Modal 3D sign
		transition: transform .3s ease, opacity .3s ease;
  }
  
  .Modal-content .divider {
    border-bottom: 1px solid #ffffff29;
    margin-bottom: 1.5rem;
    display: none;
  }
  
  .Modal.non-scrollable .Modal-content {
    overflow: visible;
  }
  
  .Modal-title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    margin: 2.5rem 3rem 2rem;

    @media (max-width: 576px) {
      margin: 2.5rem 2rem 1.5rem;
    }
  }
  
  .Modal-body::-webkit-scrollbar-track {
    background-color: #1c1c1c;
    border-radius: 155rem;
  }
  
  .Modal-body::-webkit-scrollbar-thumb {
    background-color: #949393;
    border-radius: 155rem;
  }
  
  .Modal-title {
    text-align: left;
    font-size: 30px;
    line-height: calc(39/30);
    font-family: "Electrolize", sans-serif;
  }
  
  .Modal-close-button {
    z-index: 30;
    position: fixed;
    right: 20vw;
    top: 10vh;
    text-align: right;
    cursor: pointer;

    @media (max-width: 1200px) {
      right: 10vw;
    }

    @media (max-width: 768px) {
      top: 5vh;
    }
  }
  
  .Modal-close-button::before {
    content: '';
    display: block;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2240%22%20height%3D%2240%22%20viewBox%3D%220%200%2040%2040%22%20fill%3D%22none%22%3E%0A%3Cg%20clip-path%3D%22url%28%23clip0_712_212%29%22%3E%0A%3Cpath%20d%3D%22M40%203.5L36.5%200L20%2016.5L3.5%200L0%203.5L16.5%2020L0%2036.5L3.5%2040L20%2023.5L36.5%2040L40%2036.5L23.5%2020L40%203.5Z%22%20fill%3D%22%236988FF%22%2F%3E%0A%3C%2Fg%3E%0A%3Cdefs%3E%0A%3CclipPath%20id%3D%22clip0_712_212%22%3E%0A%3Crect%20width%3D%2240%22%20height%3D%2240%22%20fill%3D%22white%22%2F%3E%0A%3C%2FclipPath%3E%0A%3C%2Fdefs%3E%0A%3C%2Fsvg%3E');
    background-repeat: no-repeat;

    @media (max-width: 768px) {
      content: '';
      display: block;
      flex-shrink: 0;
      width: 25px;
      height: 25px;
      background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2225%22%20height%3D%2225%22%20viewBox%3D%220%200%2025%2025%22%20fill%3D%22none%22%3E%0A%3Cpath%20d%3D%22M25%202.1875L22.8125%200L12.5%2010.3125L2.1875%200L0%202.1875L10.3125%2012.5L0%2022.8125L2.1875%2025L12.5%2014.6875L22.8125%2025L25%2022.8125L14.6875%2012.5L25%202.1875Z%22%20fill%3D%22%236988FF%22%2F%3E%0A%3C%2Fsvg%3E');
      background-repeat: no-repeat;
    }
  }
  
  .Modal-close-button svg {
    display: none;
  }
  
  .Modal-close-icon {
    opacity: 0.6;
    cursor: pointer;
    text-align: right;
    display: inline-block;
  }
  
  .Modal-close-icon:hover {
    opacity: 0.9;
  }
  
  .Modal-note {
    margin-bottom: 1.5rem;
    margin-top: 0.8rem;
  }
  
}

