.Options-Interface {
  .Tab.inline div.Tab-option {
    font-size: 13px;
    line-height: calc(16.9/13);
  }
  
  .Tab.Tab-inline-icon {
    white-space: nowrap;
  }
  .Tab.Tab-inline-icon .Tab-option-icon {
    margin-left: 0;
  }
  .Tab.Tab-inline-icon .Tab-option {
    display: flex;
    align-items: center;
    column-gap: 5px;
    margin-left: -5px;
  }
  
  .Tab.block .Tab-option-icon {
    transform: translateY(1px);
  }
}
