@import '../../../../style/vars.scss';

.BuilderForm {
	padding: 0;

	&__inputs {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		grid-template-rows: 1fr 1fr auto;
		column-gap: 30px;
		row-gap: 20px;
		padding: 20px 30px;

		.Tab.block .Tab-option {
			font-size: 15px;
			line-height: calc(19.5/15);
			padding: 8.5px 16px;
		}

		@media (max-width: 576px) {
			padding: 10px 15px 20px;
		}
	}

	&__preview {
		&-img {
			text-align: center;
			background-color: $blue-15;
			border: $border;
			border-radius: 5px;
			padding: 7px 16px;

			img {
				width: 100%;
			}

			@media (min-width: 2000px) {
				padding: 7px 5px;
			}
		}
		&-stats {
			display: flex;
			flex-wrap: wrap;
			gap: 6px;
			margin-top: 6px;
		}
		&-stat {
			display: flex;
			font-size: 12px;
			line-height: calc(15.6/12);
			background-color: $blue-05;
			border-radius: 3px;
			border: $border;
			&-name {
				color: $lgrey;
				border-right: $border;
			}
			&-val {
				color: $white;
			}
			&-name, &-val {
				padding: 3.5px 5px;
			}
		}

		@media (max-width: 992px) {
			display: flex;
			flex-direction: column-reverse;

			&-stats {
				margin-top: 0;
				margin-bottom: 6px;
			}
		}
		@media (max-width: 480px) {
			&-stat {
				font-size: 10px;
				line-height: calc(13/10);
				&-name, &-val {
					padding: 3.5px 2.5px;
				}
			}
		}
	}

	&__buyer {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		column-gap: 30px;
		row-gap: 10px;
		background-color: $blue-15;
		padding: 17px 30px;

		&-fields {
			grid-column: 1/3;
			display: flex;
			column-gap: 30px;
			row-gap: 8px;
			align-items: center;
			justify-content: flex-end;

			@media (max-width: 992px) {
				flex-wrap: wrap;
			}

			@media (max-width: 768px) {
				flex-direction: column;
				align-items: flex-start;
				row-gap: 5px;
			}
		}

		&-field {
			display: flex;
			column-gap: 15px;
			justify-content: flex-end;
			align-items: center;

			.BuilderForm__field-title {
				margin-bottom: 0;
			}

			.BuilderForm__field-content {
				display: flex;
				column-gap: 10px;
			}

			@media (max-width: 480px) {
				width: 100%;
				justify-content: space-between;
			}
		}

		@media (max-width: 576px) {
			padding: 15px;
		}
	}
	&__button {
		height: min-content;
		white-space: nowrap;
		grid-column: 3/4;
		&[disabled] {
			color: $lgrey;
			background-color: $blue-05;
		}
	}
	
	&__field {
		.Spinner {
			height: 42.3px;
			padding: 10px 0;
			margin: 0 auto;
		}
		
		&-title {
			font-size: 15px;
			line-height: calc(19.5/15);
			color: $lgrey;
			margin-bottom: 10px;
		}
		&:nth-child(3) {
			grid-column: 1/5;
		}
		&:nth-child(4) {
			grid-column: 1/3;
		}
		&:nth-child(5) {
			grid-column: 3/5;
		}
		// Chart
		&:nth-child(6) {
			grid-column: 5/7;
			grid-row: 1/3;
		}
		// Collateral
		&:nth-child(7) {
			grid-column: 5/7;
		}

		&_multiple &-content {
			display: flex;
			flex-direction: column;
			row-gap: 10px;

			&-line {
				min-height: 42px;
				display: flex;
				align-items: center;
			}
		}

		@media (max-width: 1300px) {
			&:nth-child(2) {
				grid-column: 2/5;
			}
		}
		@media (max-width: 1200px) {
			&:nth-child(2) {
				grid-column: 2/4;
			}
			&:nth-child(3) {
				grid-column: 1/4;
			}
			&:nth-child(4) {
				grid-column: 1/4;
			}
			&:nth-child(5) {
				grid-column: 4/7;
			}
			&:nth-child(6) {
				grid-column: 4/7;
			}
			&:nth-child(7) {
				grid-column: 4/7;
			}
		}

		@media (max-width: 400px) {
			.InputWithSelector {
				flex-direction: column;

				.Selector {
					width: 100%;
					&__items {
						left: 0;
					}
				}
			}
		}
	}
	@media (max-width: 992px) {
		&__inputs {
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: auto;
		}
		& > &__inputs > &__field {
			grid-column: 1/3;

			&:nth-child(1), &:nth-child(4) {
				grid-column: 1/2;
			}
			&:nth-child(2), &:nth-child(5) {
				grid-column: 2/3;
			}
			&:nth-child(6) {
				grid-column: 1/2;
				grid-row: 3/4;
			}
			&:nth-child(7) {
				grid-column: 2/3;
			}
		}
	}
	@media (max-width: 768px) {
		& > &__inputs > &__field {
			&:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7) {
				grid-column: 1/3;
			}
		}
		&__button {
			grid-column: 2/4;
		}
		&__buyer-field {
			grid-column: 1/2;
		}
	}
	@media (max-width: 576px) {
		&__buyer {
			display: flex;
			flex-direction: column;
			row-gap: 10px;
		}
		&__buyer-field {
			justify-content: space-between;
		}
	}
	@media (max-width: 480px) {
		& > &__inputs > &__field {
			&:nth-child(1), &:nth-child(2) {
				grid-column: 1/3;
			}
		}
	}

	.Selector &__preview {
		border: $border;
		border-radius: 5px;
		background-color: $blue-15;
		padding: 7px 10px 10px;
		
		&-img {
			border: none;
			padding: 0;
		}

		@media (max-width: 768px) {
			display: none;
		}
	}
}