@import '../../../style/vars.scss';

.Selector {
	position: relative;

	&.disabled &__chosen-item {
		cursor: not-allowed;
		&-info {
			color: $grey;
		}

		&:hover, &:focus-visible {
			background-color: $blue-15;
		}
	}

	&__chosen-item {
		display: flex;
		column-gap: 2px;
		align-items: center;
		height: 100%;

		transition: color .2s ease;
		&:hover, &:focus-visible {
			color: $lblue;
		}

		&-info {
			white-space: nowrap;
		}
		
		.icon-down {
			position: relative;
			bottom: 2px;
			transition: transform .2s ease;
		}

		&-complex-content {
			display: flex;
			align-items: center;
			column-gap: 7px;
			flex-grow: 0;
			
			& > * {
				line-height: 0;
			}

			.market {
				line-height: 0;
				background-color: $blue-05;
				border: $border;
				border-radius: 5px;
				padding: 1px 4px;

				img {
					width: 32px;
				}
			}

			&, .Currency {
				@media (max-width: 480px) {
					font-size: 13px;
					line-height: 130%;
				}
			}
		}
	}
	
	&__items {
		display: none;
		position: absolute;
		right: 0;
		top: calc(100% + 10px);
		z-index: 80;
		box-shadow: 3px 0 20px rgba(6,13,33,0.5);
	}

	&__item {
		position: relative;
		background-color: $blue-3;
		border: $border;
		border-radius: 5px;
		padding: 9px 13px;

		transition: color .2s ease;
		&:hover, &:focus-visible {
			color: $white;
		}

		&-empty-note {
			color: $grey;
			cursor: default;
		}
	}
	
	&.open > &__items {
		display: block;
	}
	&.open > &__chosen-item .icon-down {
		transform: rotate(180deg);
	}

	&__hover-tip {
		display: none;
		position: absolute;
		top: 5px;
		right: 24px;
	}
	&__item:hover &__hover-tip, &__item:focus-visible &__hover-tip {
		display: block;
	}
	&__item.chosen:hover &__hover-tip, &__item.chosen:focus-visible &__hover-tip {
		display: none;
	}
}

// Selector types
// _wide
.Selector {
	&_wide &__chosen-item {
		width: 100%;
		font-size: 15px;
		line-height: calc(19.5/15);
		color: $white;
		background-color: $blue-15;
		border-radius: 5px;
		border: $border;

		transition: background-color .2s ease;
		&:hover, &:focus-visible {
			// border-color: $lblue;
			background-color: $blue-1;
		}

		&.default-text {
			color: $lgrey;
		}
	}
	&_wide &__chosen-item-info {
		width: 100%;
		align-items: center;
		justify-content: space-between;
		padding: 10px 13px;
	}
	&_wide &__icon {
		line-height: 0;
		background-color: $blue-05;
		border-right: $border;
		border-radius: 5px 0 0 5px;
		img {
			padding: 10px;
		}
		span {
			display: block;
			min-width: 44px;
			font-size: 12px;
			line-height: calc(13.2/12);
			padding: 7px 2px;
		}
	}
	&_wide &__items, &_wide &__item {
		width: 100%;
		background-color: $blue-2;
	}
	&_wide &__items {
		font-size: 14px;
		line-height: calc(18.2/14);
		border-radius: 5px;
		border: $border;
	}
	&_wide &__item {
		color: $white;
		background-color: transparent;
		border-radius: 0;
		border: none;
		transition: none;
		padding: 3px 14px;
		&:first-child {
			padding-top: 10px;
		}
		&:last-child {
			padding-bottom: 10px;
		}

		&:hover, &:focus-visible {
			color: $lblue;
			background-color: $blue-15;
		}

		&.chosen {
			color: $lgrey;

			&::after {
				background-color: $lblue;
			}
		}

		&-empty-note {
			color: $grey;
			&:hover, &:focus-visible {
				color: $grey;
				background-color: transparent;
			}
		}
	}
}

// .complex
.Selector {
	&.complex &__item-header {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 12px;
		line-height: calc(15.6/12);
		color: $lgrey;
		cursor: default;
		padding: 9px 14px;
		margin-bottom: 6px;
		&::before {
			content: '';
			position: absolute;
			left: 14px;
			right: 14px;
			height: 1px;
			bottom: 0;
			background-color: $border-color;
		}

		&:hover, &:focus-visible {
			background-color: transparent;
			color: $lgrey;
		}
	}
	&.complex &__item {
		display: flex;
		justify-content: space-between;
		align-items: center;

		&::after {
			display: none;
		}
		span:first-child {
			color: $lgrey;
		}
		&:hover, &:focus-visible {
			&:not(.chosen) span:first-child {
				color: $lblue;
			}
			&.chosen {
				color: $lgrey;
			}
		}
	}

	.complex &__chosen-item-info {
		@media (max-width: 480px) {
			padding: 10px 8px;
		}
	}
}



// Token selector
.Selector {
	&_token {
		width: 118px;
		flex-shrink: 0;
	}

	&_token &__chosen-item {
		background-color: $blue-05;
		&:hover, &:focus-visible {
			background-color: lighten($blue-05, 2);
		}
		span {
			color: $white;
		}
	}
	&_token.disabled &__chosen-item {
		&:hover, &:focus-visible {
			background-color: $blue-05;
		}
	}
	&_token &__chosen-item-info .icon-container, &__item.icon-container {
		column-gap: 5px;
	}
	&_token &__item {
		padding: 8px 12px;
		&:hover, &:focus-visible {
			background-color: $blue-05;
		}
	}
	&_token &__items {
		background-color: $blue-1;
	}

	@media (max-width: 480px) {
		&_token &__chosen-item-info > span {
			display: none;
		}
	}

	@media (max-width: 400px) {
		&_token {
			width: auto;
		}
	}
}