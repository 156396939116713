@import "../../../../style/vars.scss";

.TradingView {
	&__copyright {
		font-size: 14px;
		line-height: calc(18.2/14);
		font-weight: 400;
		color: $lgrey;
		padding: 5px 0 0 10px;
	}
}