@import '../../../style/vars.scss';

.Filter {
	position: relative;
	display: inline-block;
	
	&__header {
		display: flex;
		background-color: $blue-1;
		border-radius: 5px;
		border: $border;

		&-icon {
			background-color: $blue-05;
			border-right: $border;
			border-radius: 5px 0 0 5px;
		}
		&-icon, &-title-container {
			padding: 10px;
		}

		&-title-container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			column-gap: 20px;
		}

		&-title {
			transition: color .2s ease;
		}

		&:hover, &:focus-visible {
			.Filter__header-title {
				color: $white;
			}
		}
	}

	&__chevron-down {
		transition: transform .2s ease;
	}

	&.active &__items {
		display: block;
	}
	&.active &__chevron-down {
		transform: rotate(180deg);
	}

	&__items {
		display: none;
		position: absolute;
		top: calc(100% + 10px);
		left: 0;
		right: 0;
		z-index: 100;
		background-color: $blue-2;
		border-radius: 5px;
		border: $border;
		box-shadow: 3px 0 20px rgba(6,13,33,0.5);
	}

	&__item {
		position: relative;
		display: flex;
		width: 100%;
		padding: 5px 15px;

		&:first-child {
			padding-top: 10px;
		}
		&:last-child {
			padding-bottom: 10px;
		}

		&-icon {
			height: 20px;
		}

		input[type="checkbox"] {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			z-index: 1;
			margin: 0;

			&:focus-visible {
				background-color: $lblue;
				opacity: 0.07;
			}
		}

		transition: background-color .2s ease;
	}

	@media (max-width: 576px) {
		&, &__header, &__header-title-container {
			width: 100%;	
		}
	}
}