.borrow-market {
	&__filter {
		margin-bottom: 20px;
	}

	&__warning {
		width: 100%;
		text-align: center;
		color: $grey;
		padding: 10px;
	}

	&__items {
		position: relative;
		padding: 0 30px;

		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 80px;
			left: 0;
			right: 0;
			z-index: 1;
			height: 1px;
			background-color: $blue-1;
		}
	}
	
	&__item {
		display: flex;
		justify-content: space-between;
		gap: 30px;
		

		&:first-child &-btns {
			margin-top: 85px;
		}
		&:first-child &-option-name {
			display: flex;
		}
		&:not(:first-child) &-option-name {
			display: none;
		}

		&-option {
			&-name {
				height: 80px;
				position: relative;
				color: $grey;
				padding: 20px 0;
			}

			&-val {
				color: $llblue;
				white-space: nowrap;
				padding: 15px 0 20px;
			}
		}

		&-options {
			display: flex;
			gap: 40px;
		}

		&-btns {
			align-items: flex-start;
			padding: 7.5px 0 15px;
		}
		&-option {
			flex-shrink: 0;
			flex-grow: 0;
			font-size: 15px;
			line-height: calc(19.5/15);
			
			&:first-child {
				width: 33px;
				// width: 16px;
			}
			&:nth-child(2) {
				width: 150px;
				// width: 150px;
			}
			&:nth-child(2) &-val {
				word-break: break-all;
				white-space: normal;
			}
			&:nth-child(3) {
				width: 62px;
				// width: 62px;
			}
			&:nth-child(4) {
				width: 62px;
				// width: 45px;
			}
			&:nth-child(5) {
				width: 62px;
				// width: 68px;
			}
			&:nth-child(6) {
				width: 89px;
				// width: 72px;
			}
			&:nth-child(7) {
				width: 88px;
				// width: 71px;
			}
			&:nth-child(8) {
				width: 89px;
				// width: 100px;
			}
			&:nth-child(9) {
				width: 89px;
				// width: 72px;
			}
		}
	}

	@media (max-width: $width) {
		&__item {
			&-options {
				gap: 30px;
			}
		}
	}

	@media (max-width: 1200px) {
		&__items {
			display: flex;
			flex-wrap: wrap;
			gap: 20px;
			background-color: transparent;
			padding: 0;

			&::after {
				display: none;
			}
		}
		&__item {
			width: calc(50% - 10px);
			position: relative;
			background-color: $blue-2;
			border-radius: 5px;
			padding: 20px 30px 85px;

			&-options {
				width: 100%;
				flex-direction: column;
				gap: 13px;
			}
			&-option {
				width: auto;
				height: auto;
				display: flex;
				justify-content: space-between;
				gap: 30px;
				text-align: right;
				padding: 0;

				&:nth-child(n) {
					width: auto;
				}

				&-name, &-val {
					height: auto;
					padding: 0;
				}
				&-name {
					text-align: left;
				}
				&-val {
					text-align: right;
				}
			}

			&:not(:first-child) &-option-name {
				display: block;
			}

			&-btns {
				position: absolute;
				bottom: 0;
				left: 15px;
				right: 15px;
				bottom: 15px;
			}
		}
	}

	@media (max-width: 576px) {
		&__item {
			width: 100%;
			gap: 20px;
			padding: 20px 20px 85px;
		}
	}
}
