@import '../../../../style/vars.scss';

.BuilderPositions {
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 15px;
		margin-bottom: 15px;

		h1 {
			margin-bottom: 0;
		}
	}

	@media (max-width: 576px) {
		&__header {
			flex-direction: column;
			align-items: flex-start;
		}
	}

	@media (max-width: 480px) {
		margin-bottom: 60px;
	}
}

.BuilderPositionsList {
	.Exchange-list {
		border-top-left-radius: 0;
		tr {
			&::after {
				top: 0;
				bottom: auto;
				left: 0;
				right: 0;
			}
			&:last-child {
				&:not(.foldable):not(:first-child)::after {
					display: block;
				}
			}
			&:first-child::after {
				display: none;
			}
		}
		
	}

	.BuilderPositions__item-header {
		z-index: 1;
		background-color: $blue-15;
		&-strategy {
			display: flex;
			align-items: center;
			column-gap: 5px;

			svg {
				transition: transform .2s ease;
			}
		}
		&.active .BuilderPositions__item-header-strategy {
			svg {
				transform: rotate(180deg);
			}
		}

		td {
			padding: 8px 14px;
		}

		& + tr.foldable {
			&::after {
				display: block;
				top: 0;
				bottom: auto;
			}
		}
	}
		
	tr {
		transition: transform .25s ease;

		&.folded {
			position: absolute!important;
			opacity: 0;
			z-index: -1;
			transform: translateY(-15px);
		}
		&.foldable {
			&::after {
				display: none;
			}
			td {
				padding: 6px 14px;
			}
			&.first td {
				padding-top: 12px;	
			}
			&.last td {
				padding-bottom: 12px;
			}
		}
	}
}