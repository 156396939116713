@import '../style/vars.scss';

.MarkPricePanel {
	display: inline-flex;
	align-items: center;
	background-color: $blue-15;
	border-radius: 5px;
	border: $border;
	
	&__icon {
		line-height: 0;
		background-color: $blue-05;
		border-right: $border;
		border-radius: 5px 0 0 5px;
		padding: 15px 10px;
	}

	&__items {
		display: flex;
		align-items: center;
		column-gap: 15px;
		padding: 10px 13px;
	}

	&__item {
		width: 105px;
		&:first-child {
			width: 90px;
		}
	}

	@media (max-width: 380px) {
		width: 100%;
		justify-content: space-between;
	}
}