@import "../../../../style/vars.scss";


.Options-Interface {
	.Exchange-lists-container, .OptionsList {
		grid-column: 1/3;
	}
	.Exchange-lists {
		&__title {
			margin-bottom: 45px;
		}
	}
	
	.Exchange-list-tip {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		font-size: 13px;
		line-height: calc(16.9/13);
		color: $grey;
		vertical-align: bottom;
	}
	.Exchange-list-tab-container {
		display: flex;
		justify-content: space-between;
		column-gap: 20px;
		row-gap: 10px;
		flex-wrap: wrap-reverse;
	}

	.Exchange-left {
		min-height: 65rem;

		@media (max-width: 1100px) {
			min-height: auto;
		}
	}

	.ExchangeChart-top-inner {
		flex-direction: row;

		@media (max-width: 1300px) and (min-width: 1250px) {
			column-gap: 30px;
			.ExchangeChart-stats-container {
				column-gap: 45px;

				& > div:not(:last-child)::after {
					right: -22.5px;
				}
			}
		}
		@media (max-width: 920px) {
			flex-direction: column;
		}
	}

	.Exchange-list.small .App-card-options {
		justify-content: flex-end;
	}
	

	@media (max-width: 1250px) {
		.Exchange-lists-container, .OptionsList {
			grid-column: auto;
		}
		.Exchange-list.large {
			display: none;
		}
		.Exchange-list.small {
			display: table;
		}
		
		.Exchange-content {
      grid-template-columns: 1fr;
    }

		.Exchange-left {
			max-width: 100%;
		}
		.Exchange-right {
			max-width: 700px;
			margin: 0 auto;
			width: 100%;
		}

		.Exchange-list.small > div {
			display: flex;
			flex-wrap: wrap;
			column-gap: 1.5rem;

			.App-card {
				width: calc(50% - 0.75rem);
				border-radius: 5px;
				
				&:first-child {
					border-top-left-radius: 0;
				}
			}
			.Exchange-list-empty-note {
				width: 100%;
			}
		}
	}

	@media (max-width: 768px) {
		.Exchange-list.small > div {
			display: block;

			.App-card {
				width: 100%;
			}
		}
	}
}