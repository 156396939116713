/* colors */
$white: #fff;

$llblue: #98A2C6;
$lblue: #6988FF;
$green: #46956A;
$red: #C75E4B;
$orange: #C68556;
$grey: #384263;
$grey-2: #3A4466;
$lgrey: #747FA6;
$blue-05: #20224E;
$blue-1: #181A3C;
$blue-15: #141637;
$blue-17: #151632;
$blue-2: #0D0F29;
$blue-3: #030A21;

// Old colors
// $green: #019645;
// $red: #892C1F;

/* colors end */

$width: 1360px;
$title: 'Electrolize', sans-serif;
$border: 1px solid rgba(56, 66, 99, 0.2);
$border-color: rgba(56, 66, 99, 0.2);
$outline: -webkit-focus-ring-color auto 1px;