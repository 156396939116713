@import "../../../../style/vars.scss";

.UserGraph {
	margin-bottom: 30px;
	
	&__content {
		display: flex;
		column-gap: 30px;

		& > div {
			flex-grow: 1;
			@media (max-width: 1200px) {
				width: 100%;
			}
		}

		@media (max-width: 1200px) {
			flex-direction: column;
			row-gap: 20px;
		}
	}

	&__graph {
		position: relative;
		width: 63%;
		max-height: 311px;
		padding: 10px;
		
		&-canvas {
			display: flex;
			align-items: center;
			justify-content: center;
			
			&, canvas {
				height: 100%;
				min-height: 300px;
			}
		}

		&-markets-icons {
			position: absolute;
			top: 20px;
			left: 25px;
			display: flex;
		}
		&-markets-icon {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 40px;
			height: 40px;
			background-color: $blue-1;
			border-radius: 50%;
			border: $border;
			box-shadow: 5px 0 10px rgba(7,13,32,0.25);
			// GMX
			&:nth-child(1) {
				z-index: 1;
				order: 3;
				margin-left: -10px;
			}
			// GNS
			&:nth-child(2) {
				z-index: 0;
				order: 4;
				margin-left: -10px;
			}
			// Hegic
			&:nth-child(3) {
				z-index: 2;
				order: 2;
				margin-left: -10px;
			}
			// Lyra
			&:nth-child(4) {
				z-index: 3;
				order: 1;
				img {
					transform: translateX(-2px) translateY(-1px);
				}
			}

			img {
				width: 20px;
			}
		}
		@media (max-width: 480px) {
			padding: 0;
			&-markets-icons {
				top: 15px;
				left: 15px;
			}
			&-markets-icon {
				width: 30px;
				height: 30px;
				img {
					width: 15px;
				}
			}
		}
	}
	&__overview {
		width: 37%;
		padding: 20px 30px;

		h2 {
			font-family: $title;
			font-size: 25px;
			line-height: calc(32.5/25);
			font-weight: 400;
			margin-bottom: 15px;
		}

		&-items {
			display: flex;
			flex-direction: column;
			row-gap: 15px;
		}
		&-item {
			display: flex;
			justify-content: space-between;
			column-gap: 15px;
			background-color: $blue-15;
			border: $border;
			border-radius: 5px;
			padding: 20px;
			white-space: nowrap;

			&-title {
				color: $grey;
			}
			&-pnl {
				color: $white;
				&-percentage {
					margin-left: 5px;
				}
			}
		}

		@media (max-width: 576px) {
			padding: 15px 20px;
			
			h2 {
				margin-bottom: 10px;
			}
			&-item {
				align-items: center;
				flex-direction: column;
				row-gap: 3px;
				font-size: 15px;
				line-height: calc(19.5/15);
				padding: 14px 10px;
			}
		}
	}
}