.Options-Interface {
	.Exchange-lists {
		&__title {
			margin-bottom: 15px;
		}
		&-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			& > div {
				margin-bottom: 15px;
			}
		}

		@media (max-width: 576px) {
			&-header {
				flex-direction: column;
				align-items: flex-start;
			}
		}
	}
}