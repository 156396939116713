@import '../../../../style/vars.scss';

.OptionsList {
	position: relative;
	margin-top: 60px;
	
	&__title {
		margin-bottom: 0;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		column-gap: 20px;
		margin-bottom: 20px;
	}


	@media (max-width: 1100px) {
		margin-top: 0;
		margin-bottom: 60px;

		.Tooltip_disabled {
			.Tooltip-popup {
				right: 0;
				left: auto;

				&::before {
					left: auto;
					right: 12px;
				}
			}
		}
	}

	@media (max-width: 576px) {
		&__header {
			flex-direction: column;
			row-gap: 15px;
			margin-bottom: 15px;
		}
	}

	&__unfold-btn {
		display: block;
		width: 200px;
		margin: 20px auto 0;

		@media (max-width: 480px) {
			width: 100%;
		}
	}

	table.Exchange-list tr.Exchange-list-header::after {
		left: 0;
		right: 0;
	}

	table.Exchange-list td {
		padding: 13px 12px;
	}

	.Exchange-list {
		td {
			white-space: nowrap;
		}

		tbody tr {
			td {
				border-bottom: 1px solid rgba(56, 66, 99, 0.14);
			}

			&:last-child td {
				border-bottom: none;
			}
		}
	}
}

table tr.hlight {
	background-color: rgba(70, 149, 106, 0.05);
}

.Options-item {
	&__btn {
		display: inline-flex;
		justify-content: flex-end;
	}
}