@import "../../../style/vars.scss";

.NumberInput {
	&__displayValueTitle {
		color: $grey-2;
	}

	&__additionalContainer {
		display: flex;
		column-gap: 12px;
		align-items: center;
	}
	&__additionalSymbol {
		font-family: $title;
		font-size: 13px;
		line-height: calc(16.9/13);
	}
}